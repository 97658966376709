/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import './Request.scss';
// import { Formik, Field, Form } from "formik";
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import { basePath, apiVersion, headers} from '../../api/config';
import Ellipse from '../../Img/RequestAQuote/Ellipse.png';
import Monitor from '../../Img/RequestAQuote/Monitor.png';
import Shoppin from '../../Img/RequestAQuote/Shoppin.png';
import Smartphone from '../../Img/RequestAQuote/Smartphone.png';
import $ from "jquery";



class CollapsePage extends Component {
	
	render() {
		const option =(value)=>{
			var monitor ='<img class="monitor" src="'+Monitor+'"/>';
			// var smartphone ='<img class="smartphone" src="'+Smartphone+'"/>';
			// var shoppin ='<img class="shoppin" src="'+Shoppin+'"/>';
			$("#smartphone").html(monitor);

			$("."+value).css({"background-color": "yellow!important", "font-size": "200%"});
				// box-shadow: 4px 6px 10px rgba(54, 126, 142, 0.35);
				// border-radius: 10px;});
		}
		return (
			<div class="row principal">
					<div className="row col-sm-6 col-md-6 col-lg-6 col-xl-6">
						
						<div className="content-ellipse">
							<img className="ellipse" src={Ellipse} />
						</div>
						
						<div id="monitor" className="content-monitor">
							<img className="monitor" src={Monitor} />
						</div>
						
						<div id="smartphone" className="content-smartphone justify-content-rigth">
							<img className="smartphone" src={Smartphone} />
						</div>	

						<div id="shoppin" className="content-shoppin">
							<img className="shoppin" src={Shoppin} />
						</div>

						<div className="content-description-ellipse">
							<p className="description-ellipse"><b>PROJECT ESTIMATION</b></p>
						</div>
					</div>
				
				<div className="row app-content container col-sm-6 col-md-6 col-lg-6 col-xl-6">
					
						<div className="app">
							<div className="ask1">
								<p className="one">I want build an app</p>
							</div>
							<div className="app-option col-md-12 mt-3 mb-3">
								<button id="1" className="option btn btn-block" onClick={()=>{option(1)}}>Web App</button>
							</div>
							<div className="app-option col-md-12 mt-3 mb-3">
								<button  id="2" className="option btn  btn-block" onClick={()=>{option(2)}}>Mobile App</button>
							</div>
							<div className="app-option col-md-12 mt-3 mb-3">
								<button   id="3" className="option btn btn-block" onClick={()=>{option(3)}}>E-commerce</button>
							</div>
							<div className="app-option col-md-12 mt-5 mb-3">
								
							</div>
						</div>
				</div>
				
			</div> 
		);
		// return (
		// 	<div className="App">
		// 	  <h1>Contact Us</h1>
		// 	  <Formik
		// 		initialValues={{ name: "", email: "" }}
		// 		onSubmit={async values => {
		// 		const url = `${basePath}/${apiVersion}/form-data`;
        //         return axios.post(url,{data:values}, headers)
        //         .then(response => {
		// 				console.log(response.data);
		// 				if(response.data.status === 200){
		// 					toast.success("holaaaaa");
		// 					console.log("si");
		// 					// toast.success(`${response.data.message}`);
		// 				}else{
		// 					console.log("no");
		// 					// toast.error(`${response.data.message}`);
		// 				}
        //         }).catch(function(e) {	
        //             console.log(e);
        //         });
		// 		}}
		// 	  >
		// 		<Form>
		// 		  <Field name="name" type="text" />
		// 		  <Field name="email" type="email" />
		// 		  <button type="submit">Submit</button>
		// 		</Form>
		// 	  </Formik>
		// 	</div>
		//   );
	}
}

export default CollapsePage;
