import React from 'react';
import './Loading.scss'

import Sphera from '../../Img/Slider/3.png'


const Loading = () => {
  return (
    <div className='containerLoading'>
      <img src={Sphera} alt="" className='loadingImg'/>
      <p className='loadingText'>LOADING...</p>
      </div>
    
  )
}

export default Loading