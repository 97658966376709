import React from 'react';
import CarouselWeb from '../OurProjectsCarousel/CarouselWeb/CarouselWeb';
import CarouselEcommerce from '../OurProjectsCarousel/CarouselEcommerce/CarouselEcommerce';
import CarouselMovil from '../OurProjectsCarousel/CarouselMobile/CarouselMobile';
import './OurProjects.scss';

const OurProyects = (props) => {
	const { idioma } = props;


	return (
		<div className="container">
			<div className="contenedor">
				<div className="title-contenedor">
					<div>
						<div className="title-portfolio">
							<h1>We code with love </h1>
						</div>
					</div>
				</div>

				<div className="section-box box-1">
					<div className='box-color'></div>
					<div className="section-title web-title">
						<hr></hr>
						<h4 className="title-web">{idioma.TitleWASection}</h4>
						<hr></hr>
					</div>

					<div className="row">
						<div className="cols col-sm-12 col-md-12 col-lg-12 col-12 col-xxl-12">
							<div className="carousel-box-left">
								<CarouselWeb idioma={idioma} />
							</div>
						</div>
					</div>
				</div>

				<div className="section-box box-2">
					<div className='box2-color'></div>
					<div className="section-title">
						<hr></hr>
						<h4>{idioma.TitleEcommerceSection}</h4>
						<hr></hr>
					</div>
					<div className="row">
						<div className="cols col-sm-12 col-md-12 col-12 col-lg-12 col-xxl-12">
							<div className="carousel-box-right">
								<CarouselEcommerce idioma={idioma} />
							</div>
						</div>
					</div>
				</div>

				<div className="section-box mobile-box">
					<div className="box3-color"></div>
					<div className="section-title title-mobile">
						<hr></hr>
						<h4 className="title-mobile">{idioma.TitleMobile}</h4>
						<hr></hr>
					</div>

					<div className="row">
						<div className="cols col-sm-12 col-md-12 col-12 col-lg-12 col-xxl-12">
							<div className="carousel-box-left izquierdo">
								<CarouselMovil idioma={idioma} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OurProyects;
