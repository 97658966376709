import React from 'react'
import './NotFound.scss'
import Sphere1 from "../../Img/Slider/IllustrationSphere.png";
import Sphere2 from "../../Img/Slider/IllustrationSphere2.png";
import Sphere3 from "../../Img/Slider/Illustration Sphere.png";
import SphereH3 from "../../Img/Slider/3.png";
import Wave from "../../Img/NotFound/Vector.png"
import Wave2 from "../../Img/NotFound/Vector2.png"

const NotFound = () => {

    return (
        <section>        
            <img src={Wave} className="wave-not" alt='wave-decoration'></img>
            <img src={Wave2} className="wave-not2" alt='wave-decoration'></img>
            <div className="container">                
            <div className="contenedor not-found">            
            <img src={SphereH3} className="SphereH3" alt='sphere-decoration'></img>
            <img src={Sphere1} className="Sphere10" alt='sphere-decoration'></img>
                <div className="row">
                    <img src={Sphere1} className="Sphere1" alt='sphere-decoration'></img>                   
                     <img src={Sphere3} className="Sphere3" alt='sphere-decoration'></img>
                    <h1 className="code col-12">404</h1>
                    <h1 className="message col-12">Not found</h1>
                    <p className="col-12 messages">Sorry, page does not found</p>
                    <a href="/" className="go-back">
                    Go Back Home
                    </a>
                </div>
                <img src={SphereH3} className="SphereH31" alt='sphere-decoration'></img>
                <img src={Sphere2} className="Sphere2" alt='sphere-decoration'></img>

                <img src={Sphere1} className="Sphere12" alt='sphere-decoration'></img>
            </div>
            <img src={Sphere2} className="Sphere20" alt='sphere-decoration'></img>
        </div>
        </section>

    )
}

export default NotFound
