import React from 'react';
import Adopt from '../../../Img/Portfolio/WebApplications/Adopt.png';
import Domene from '../../../Img/Portfolio/WebApplications/Domene.png';
import Dalton from '../../../Img/Portfolio/WebApplications/Dalton.png';
import USQBC from '../../../Img/Portfolio/WebApplications/USQBC.png';
import LaMichoacana from '../../../Img/Portfolio/WebApplications/LaMichoacana.png';
import Rentio from '../../../Img/Portfolio/WebApplications/Rentio.png';
import FLUTER from '../../../Img/DevelopmentTechnologies/FLUTTER.png';
import JQUERY from '../../../Img/DevelopmentTechnologies/JQUERY.png';
import JavaScript from '../../../Img/DevelopmentTechnologies/JAVASCRIPT.png';
import ANGULAR from '../../../Img/DevelopmentTechnologies/ANGULAR.png';
import NET from '../../../Img/DevelopmentTechnologies/NET.png';
import PHP from '../../../Img/DevelopmentTechnologies/PHP.png';
import BOOTSTRAP from '../../../Img/DevelopmentTechnologies/BOOTSTRAP.png';
import WORDPRESS from '../../../Img/DevelopmentTechnologies/WORDPRESS.png';
import RUBY from '../../../Img/DevelopmentTechnologies/RUBY.png';
import HSTV from '../../../Img/Portfolio/WebApplications/HSTV.png'
import NEXTJS from '../../../Img/Metodology&Performance/NEXTJS.png';
import TS from '../../../Img/DevelopmentTechnologies/TS.png'
import LISTINGKEY from '../../../Img/Portfolio/WebApplications/LISTINGKEY.png'
import CABORENTED from '../../../Img/Portfolio/WebApplications/CABORENTED.png'

import './CarouselWeb.scss';
import Slider from "react-slick";


const CarouselWeb = (props) => {
	const { idioma } = props;
	var settings = {
		dots: true,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: true,
		responsive:
			[
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}

				},
				{
					breakpoint: 320,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 1140,
					settings: {
						slidesToShow: 2,
					}
				},
			]

	};

	return (
		<div className="" >
			<Slider {...settings}>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={Dalton}
						alt="First slide"
					/>
					<h1 className="title-card">Dalton Seminuevos</h1>
					<h4 className="description-card">
						{idioma.Dalton}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={NET}
								alt=".NET"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={ANGULAR}
								alt="Angular"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={JavaScript} alt="JavaScript" className="iconTechnology" />
						</div>
						<div className="iconTechnology">
							<img src={BOOTSTRAP} alt="BOOTSTRAP" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={HSTV}
						alt="First slide"
					/>
					<h1 className="title-card">HSTV</h1>
					<h4 className="description-card">
						{idioma.HSTV}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={NEXTJS}
								alt="NEXTJS"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={TS} alt="TS" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={USQBC}
						alt="First slide"
					/>
					<h1 className="title-card">USQBC</h1>
					<h4 className="description-card">
						{idioma.USQBC}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={WORDPRESS}
								alt="WORDPRESS"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={PHP} alt="PHP" className="iconTechnology" />
						</div>
						<div className="iconTechnology">
							<img src={JQUERY} alt="JQUERY" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={Rentio}
						alt="First slide"
					/>
					<h1 className="title-card">RentIO</h1>
					<h4 className="description-card">
						{idioma.Rentio}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={FLUTER}
								alt="Flutter"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={ANGULAR}
								alt="Angular"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={PHP} alt="PHP" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={LaMichoacana}
						alt="First slide"
					/>
					<h1 className="title-card">La Michoacana</h1>
					<h4 className="description-card">
						{idioma.LaMichoacana}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={JavaScript}
								alt="JavaScript"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={NET} alt=".net" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={Adopt}
						alt="First slide"
					/>
					<h1 className="title-card">Adopt Together</h1>
					<h4 className="description-card">
						{idioma.AdoptTogether}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={RUBY}
								alt="Ruby on Rails"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={BOOTSTRAP}
								alt="BOOTSTRAP"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={JQUERY} alt="JQUERY" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={Domene}
						alt="First slide"
					/>
					<h1 className="title-card">Instituto Domene</h1>
					<h4 className="description-card">
						{idioma.Domene}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={JavaScript}
								alt="JavaScript"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={PHP}
								alt="PHP"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={JQUERY} alt="JQUERY" className="iconTechnology" />
						</div>
						<div className="iconTechnology">
							<img src={BOOTSTRAP} alt="BOOTSTRAP" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={LISTINGKEY}
						alt="First slide"
					/>
					<h1 className="title-card">LISTINGKEY</h1>
					<h4 className="description-card">
						{idioma.ListingKey}
					</h4>
					<div className='wrapper-icons'>

						<div className="iconTechnology">
							<img
								src={ANGULAR}
								alt="Angular"
								className="iconTechnology"
							/>
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={CABORENTED}
						alt="First slide"
					/>
					<h1 className="title-card">Cabo Rented</h1>
					<h4 className="description-card">
						{idioma.Rentio}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img src={JQUERY} alt="JQUERY" className="iconTechnology" />
						</div>
						<div className="iconTechnology">
							<img src={PHP} alt="PHP" className="iconTechnology" />
						</div>
						<div className="iconTechnology">
							<img src={JavaScript} alt="JavaScript" className="iconTechnology" />
						</div>
						<div className="iconTechnology">
							<img src={BOOTSTRAP} alt="BOOTSTRAP" className="iconTechnology" />
						</div>
					</div>
				</div>
			</Slider>
		</div>
	)
};

export default CarouselWeb;
