import './Styles/App.css';
import React, { useEffect, lazy, Suspense, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import NavBar from './Components/NavBar';
import AboutUs from './Pages/AboutUS';
import Chatbot from './Components/ChatBot';
import Portfolio from './Pages/Portfolio';
import RequestAquote from './Pages/Request-a-quote';
import NotFound from './Components/NotFound';
import { MDBContainer } from 'mdbreact';
import Loading from './Components/Loading/Loading';
import Translate from './Translate/Translate';
import CookieConsent from "react-cookie-consent";
import BtnUp from './Components/BtnUp/BtnUp';
import Whatsappbot from './Components/WhatsappBot';
import ChatBotBubble from './Components/ChatBotBubble/ChatBotBubble';
import Nav from './Components/NavMobile/Nav';


const Home = lazy(() => import('./Pages/Home/Home'));

function App() {
	const [idioma, setIdioma] = useState('ESP');
	const [jsonIdioma, setJsonIdioma] = useState(Translate.ESP)

	useEffect(() => {
		if (idioma === 'ESP') {
			setJsonIdioma(Translate.ESP)
		}
		else {
			setJsonIdioma(Translate.ENG)
		}
	}, [idioma])

	const handleChangeLanguage = (idioma) => {
		setIdioma(idioma)
	}

	//Google Analitics
	useEffect(() => {
		ReactGA.initialize('UA-155926835-1');
		// to report page view
		// ReactGA.pageview(window.location.pathname);
		ReactGA.pageview('/home');
		ReactGA.pageview('/portfolio');
		ReactGA.pageview('/about-us');
	}, []);

	return (
		<>
			<Suspense fallback={<Loading />}>
				<MDBContainer id="beginning" fluid>
					<NavBar handleChangeLanguage={handleChangeLanguage} currentLanguage={idioma} idioma={jsonIdioma} />
					<Nav handleChangeLanguage={handleChangeLanguage} currentLanguage={idioma} idioma={jsonIdioma} />
					{/* <Router> */}
					<Switch>
						<Route exact path="/"><Home idioma={jsonIdioma} /></Route>
						<Route exact path="/portfolio"><Portfolio idioma={jsonIdioma} /></Route>
						<Route exact path="/about-us" ><AboutUs idioma={jsonIdioma} /></Route>
						<Route exact path="/request-a-quote" component={RequestAquote} />
						<Route component={NotFound} />
					</Switch>
					{/* </Router> */}
					<ChatBotBubble currentLanguage={idioma} />
					{/* <Chatbot /> */}
					{/*<Whatsappbot currentLanguage={idioma}/> */ }
					{/* <Footer/> */}
					<BtnUp />
					<CookieConsent 
						location="bottom"
						buttonText="I understand."
						cookieName="cookiesColimasoft"
						style={{ background: "#00c1ff", color: "black", }}
						buttonStyle={{ color: "#00000", fontSize: "18px",  background: "#fffff",borderRadius: "5px",padding: "10px 40px", }}
						expires={5}>This website uses cookies to enhance the user experience.
						</CookieConsent>
				</MDBContainer>
			</Suspense>
		</>
	);
}


export default App;
