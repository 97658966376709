const Translate = {
    "ENG":{
        "Home": "Home",
        "Portfolio": "Portfolio",
        "AboutUs": "About Us",
        "ContactUs":"Contact Us",
        "TitleSecond": "Software development services",
        "TitleServices":"Services",
        "SubTitle1":"Mobile Applications",
        "DescriptionMA":"We develop mobile cross-platform, hybrid and native applications for Android, iOS & HarmonyOS, with the best UX/UI design.",
        "SubTitleWA":"Web Applications",
        "DescriptionWA":" Our web development team considers improvement and analysis of your business processes reflected in a web application.",
        "TitleE":"eCommerce",
        "DescriptionE":"Sell the 24 hours, 365 days of the year. Send your products anywhere you want with an online store.",
        "TitleSA":"Staff augmentation",
        "DescriptionSA":"Boost your dev team with our dev team.",
        "TitleTechnologies":"Technologies",
        "TitleDevelopment":"Development Tools",
        "TitleMetodology":"Block chain & Machine learning",
        "TitleDeploy":"Deploy & Cluster",
        "TitleQA":"Quality Assurance",
        "TitleEcommerce":"CMS & e-Commerce Solutions",
        "TitleIntegration":"Integration",
        "TitleWhyC":"Why ColimaSoft?",
        "LC":"Low Cost Apps",
        "CTY":"Close to you",
        "Protection":"USMCA Protection",
        "TimeZone":"Same time zone",
        "Face":"Face to face",
        "Cultural":"Cultural affinity",
        "VideoText1":"We code with",
        "VideoTextL":"love",
        "VideoText2":", that's why we enjoy",
        "VideoTextM":"making great things",
        "VideoText3":"with the right people.",
        "TitlePartners":"Our trusted customers",
        "TextFooter":"That's why we enjoy making great things with the right people.",
        "TitleColumn1":"Services",
        "TextColumn1":"Our team is here to answer your questions.",
        "TitleColumn2":"Human Resources",
        "TextColumn2":"We're looking for new talents.",
        "Location":"Location",
        "FirstL":"Colima, Mexico",
        "Mexico":"MEXICO",
        "Direction1":"Espinela 183, Residencial Sta. Barbara, Colima, Mex. 28017",
        "Phone1":"+52 (312) 6882910",
        "SecondL":"Dallas, Texas",
        "Direction2":"4206 Newton Avenue ZIP 75219 Dallas, Texas, USA.",
        "Phone2":"+1 (469) 5098505",
        "TitleWASection":"Web applications",
        "Rentio":"RentIO is a crowdfunding platform that allows people to raise money during an economic crisis so that they can make their rent payments.",
        "Dalton":"Strengthen the company's presence by improving the buying and selling of pre-owned cars.",
        "LaMichoacana":"La michoacana foundation is a platform focused on improving the quality of lives of our communities by positively influencing education level and general wellbeing.",
        "Domene":"Instituto Domene is an ERP solution to improve the quality of the school administration from Pre-K to Middle School.",
        "AdoptTogether":"Adopt together is a non-profit, crowdfunding platform that bridges the gap between families who want to adopt and the children who need a home.",
        "HSTV":"HSTV is a web application focused on showing cooking shows as well as facilitating the sale of kitchen materials that are shown in the programs.",
        "USQBC": "USQBC is a web application that facilitates trade and investment between the United States and Qatar.",
        "CABORENTED": "CABO RENTED is a web application focused on renting houses, villas, condominium, and apartments in Los Cabos, Mexico.",
        "TitleEcommerceSection":"E-commerce",
        "MaderasPolanco": "Maderas Polanco is an eCommerce with SAP interface to facilitate their sales.",
        "BayWare": "BayWa r.e is an eCommerce focused on improving the wholesale of renewable energy.",
        "Calzzapato": "Calzzapato is an eCommerce focused on improving the wholesale of all types of shoes for any type of client.",
        "HorseCreek": "Horse Creek is an eCommerce focused on improving wholesale of Chic, comfy women's and men's clothes from a small-town boutique in Spearman, Texas.",
        "Brantano": "Brantano is an eCommerce with the purpose of incrementing their sales and reach more clients.",
        "Surtidoradepartamental": "Surtidora departmental is an eCommerce with the purpose of incrementing their sales and reach more clients.",
        "TexasTrueThreads": "Texas True Threads is an eCommerce focused on improving wholesale graphic tees and wholesale women's clothing with big-city fashion from small-town Texas.",
        "TitleMobile":"Mobile applications",
        "Colimotl":"Colimotl food is a mexican online food ordering and delivery platform.",
        "Lively": "Lively is an app by Great Call that connects your lively wearable to your iPhone with a required subscription to receive access to urgent response and other services.",
        "TaxiCDMX": "Taxi CDMX is a hybrid app developed to request a taxi service in Mexico City.",
        "TuVotoSiMX": "Tu Voto Si MX is a hybrid app developed to facilitate vote counting during presidential elections.",
        "DraIvettChávez":"Dra. Ivett Chavez is a custom-made app designed for the doctor and patient to keep track of their dossier.",
        "IDmission": "IDmission is a hybrid app with a native SDK and a plugin for Android and iOS.",
        "Aerotron": "Aerotron is a mobile app for the checking of air log on Aerotron. Saves, edits, and sends the regulatory documentation for an aircraft, and register the indicator for your instruments. Also, check the route and time information.",
        "Kiosko":"Kiosko is a mobile app focused on kiosko staff, with the aim of providing training on the internal operation of their stores.",
        "ListingKey": "ListingKey is a real estate platform focused on buying and selling houses. The web application provides a visualization of the details of the properties, including images and their location on Google maps.",
        "TailGate": "It is a social application that allows you to organize events, invite friends to them, and participate in them. ",
        "TitleAboutUs":"Meet the ",
        "team":"Team",
        "colimasoft":"We are ColimaSoft",
        "values":"Our Values",
        "vision": "Vision",
        "teamWork":"Team Work",
        "Passion":"Passion",
        "GoodWill":"Good Will",
        /**/
        "SM":"SM",
        "CTO":"CTO",
        "Developer":"Developer",
        "Designer":"UX/UI",
        "pet":"Pet",
        "OM":"Officer Manager",
        "Laura":"HR & FI",
        "DeveloperW":"Developer",
        /** */
        "TitleQuote":"GET A QUOTE",
        "Fullname":"Full name",
        "instruction1":"Complete the next fields.",
        "email":"Email",
        "phone": "Phone Number",
        "instruction2":"Pick up your feature",
        "Custom":"Custom design",
        "template":"Template",
        "ecommerce":"E-commerce",
        "web":"Web",
        "app":"Apps",
        "authentication":"Authentication",
        "landingPage":"Landing page",
        "homePage":"Home Page ",
        "sections":"Sections",
        "animation":"Animation",
        "comments":"Add specifications ",
        "validationMsg1":"Too Short!", 
        "validationMsg2":"Required",
        "validationMsg3":"Invalid email",
        "btnSend":"Send",
        "textBtnQuote":"Make a quote",
        "catalogo":'Catalog',
        "carrito":"Shopping Cart",
        'pago':"Payment Method",
        "integracionsap":"SAP",
        "CMS":"CMS",
        "facturacion":"Billing",
        "responsividad":'Responsive Design',
        "navegacion":"Navigation",
        "loading":"Loading Page",
        "profile":"Profile",
        "splashScreen":"Splash Screen",
        "chat":"Chat",
        "maps":"Maps",
        "pushNoti":"Push Notification", 
        "InAppPurchases":" In App Purchases",
        "validationPhone":"Phone must be at most 10 characters",
        "btnCancelar": "Cancel", 
        "msgQuote":"Quote Sent",
        "integration": "Integration",
        "blockChain": "BlockChain",
        "ML": "Machine Learning",
        "MsgSend":"Your information has been sent. We will contact you as soon as possible.",
        "msgBox":"Type your specification...",
        "Arca":"Arca is an ecommerce app, which is dedicated to the sale of wreaths and religious articles, all made of glassware.",
        "textNotification1":"Welcome to ColimaSoft",
        "textNotification2":"Know all our projects.",
        "textNotification3":"Know all our services.",
        "SubtitleSlider":'We solve your'

    },
    "ESP":{
        "Home": "Inicio",
        "Portfolio": "Portafolio",
        "AboutUs": "Sobre nosotros",
        "ContactUs":"Contáctanos",
        "TitleBold": "Servicios de",
        "TitleSecond":"Servicios de desarrollo de",
        "Description":"Equipando cada proyecto con los profesionales de TI más experimentados y brindando siempre la mejor actitud de servicio.",
        "TitleServices":"Servicios",
        "SubTitle1":"Aplicaciones móviles",
        "DescriptionMA":"Desarrollamos multiplatafomas móviles, aplicaciones híbridas y nativas para Android, iOS y HarmonyOS, con el mejor diseño UX/UI.",
        "SubTitleWA":"Aplicaciones Web",
        "DescriptionWA":"Nuestro equipo de desarrollo toma en consideración el análisis y el mejoramiento en el proceso de tu negocio, reflejandolo en tu aplicación web.",
        "TitleE":"Soluciones E-Commerce",
        "DescriptionE":"Vende las 24hrs/7, los 365 días del año. Envía tus productos a donde tu necesites con un tienda en línea.",
        "TitleSA":"Crecimiento del personal",
        "DescriptionSA":"Impulsa tu equipo de desarrollo con nuestro equipo de trabajo.",
        "TitleTechnologies":"Tecnologías",
        "TitleDevelopment":"Herramientas de Desarrollo",
        "TitleMetodology":"Block chain & Inteligencia Artificial",
        "TitleDeploy":"Migración & Almacenamiento",
        "TitleQA":"Verificadores de Calidad",
        "TitleEcommerce":"CMS & Soluciones e-Commerce",
        "TitleIntegration":"Integraciones",
        "TitleWhyC":"¿Por qué ColimaSoft?",
        "LC":"Apps a bajo costo",
        "CTY":"Cerca de ti",
        "Protection":"Protección T-MEC",
        "TimeZone":"Misma zona horaria",
        "Face":"Cara a cara",
        "Cultural":"Afinidad cultural",
        "VideoText1":"We code with",
        "VideoTextL":"love ",
        "VideoText2":"Disfrutamos",
        "VideoTextM":"hacer grandes cosas",
        "VideoText3":"con las personas correctas.",
        "TitlePartners":"Nuestros clientes",
        "TextFooter":"Disfrutamos hacer grandes cosas con las personas correctas.",
        "TitleColumn1":"Ventas y Servicios",
        "TextColumn1":"Contáctanos para más información.",
        "TitleColumn2":"Recursos Humanos",
        "TextColumn2":"Buscando nuevos talentos.",
        "Location":"Ubicación",
        "FirstL":"Colima, México",
        "Mexico":"MÉXICO",
        "Direction1":"Espinela 183, Residencial Sta. Bárbara, Colima, Mex. 28017",
        "Phone1":"+52 (312) 6882910",
        "SecondL":"Dallas, Texas",
        "Direction2":"4206 Newton Avenue ZIP 75219 Dallas, Texas, USA.",
        "Phone2":"+1 (469) 5098505",
        "TitleWASection":"Aplicaciones web",
        "Rentio":"RentIO es una plataforma de recaudación de fondos que permite a las personas recaudar dinero durante una crisis económica para que así puedan hacer sus pagos de alquiler.",
        "Dalton":"Tenemos desarrolladores asignados para reforzar la presencia de la compañía mejorando la compra y venta de coches de segunda mano.",
        "LaMichoacana":"La michoacana fundación es una plataforma enfocada en mejorar la calidad de vida de nuestras comunidades, influyendo positivamente en el nivel educativo y el bienestar general.",
        "Domene":"Instituto Domene es una solución ERP que mejora la calidad de la administración escolar, desde maternal hasta escuela media.",
        "AdoptTogether":"Adopt Together es una plataforma de recaudación de fondos sin fines de lucro, que cierra la brecha entre familias que quieren adoptar y niños que necesitan un hogar.",
        "HSTV":"HSTV es una aplicación web enfocada en programas de cocina, así como facilitar la venta de utensilios de cocina que se muestran durante el programa.",
        "USQBC":"USQBC es una aplicación web que facilita el comercio e inversión entre Estados Unidos y Qatar.",
        "CABORENTED": "CABO RENTED es una aplicacion web orientada a la renta de casas, villas, condominios y departamentos en Los Cabos, México.",
        "TitleEcommerceSection":"E-commerce",
        "MaderasPolanco":"Maderas Polanco es un e-commerce con interfaz SAP que facilita las ventas.",
        "BayWare":"BayWa r.e es un e-commerce enfocado en mejorar ventas al por mayor de energía renovable.",
        "Calzzapato":"Calzzapato es un e-commerce enfocado en mejorar ventas al por mayor de todo tipo de calzado para todo tipo de clientes.",
        "HorseCreek":"Horse Creek es un e-commerce enfocado en mejorar ventas al por mayor de ropa Chic, cómoda para mujer y hombre desde una pequeña boutique en Spearman, Texas.",
        "Brantano":"Brantano es un e-commerce con el propósito de incrementar sus ventas y buscar más clientes.",
        "Surtidoradepartamental":"Surtidora departamental es un e-commerce con el propósito de incrementar sus ventas y buscar más clientes.",
        "TexasTrueThreads":"Texas True Threads es un e-commerce enfocado en mejorar las ventas al por mayor de camisetas gráficas y las ventas al por mayor de ropa de mujer con la moda de la gran ciudad desde un pequeño pueblo en Texas.",
        "TitleMobile":"Aplicaciones móviles",
        "Colimotl":"Colimotl food es una plataforma online de órdenes y envíos de comida mexicana.",
        "Lively":"Lively es una aplicación de Great Call que conecta tu Lively Wearable a tu iPhone con una suscripción requerida para recibir acceso a respuestas urgentes y otros servicios.",
        "TaxiCDMX":"Taxi CDMX es una aplicación híbrida desarrollada para solicitar servicio de taxi en la Ciudad de México.",
        "TuVotoSiMX":"Tu Voto Si MX es una aplicación híbrida desarrollada para facilitar el conteo de votos durante las elecciones presidenciales.",
        "DraIvettChávez":"Dra. Ivett Chávez es app personalizada, diseñada para que los doctores y pacientes mantengan un seguimiento de sus expedientes.",
        "IDmission":"IDmission es una aplicación híbrida con un SDK nativo y un plugin para iOS y Android.",
        "Aerotron":"Aerotron es una aplicación para el registro de bitácoras aéreas. Guarda, edita y envía la documentación reglamentaria de una aeronave, registra los indicadores de sus instrumentos y registra información de ruta y tiempo.",
        "Kiosko":"Kiosko es una aplicación móvil enfocada al personal de Kiosko, con el objetivo de brindar capacitación sobre el funcionamiento interno de sus tiendas.",
        "ListingKey":"ListingKey es una plataforma inmobiliaria enfocada en la compra y venta de casas. La aplicación web proporciona la visualización de los detalles de las propiedades, incluyendo imágenes y la ubicación en Google Maps.",
        "TailGate":"Es una aplicación social con la cual puedes organizar eventos, invitar a amigos a los eventos o formar parte de ellos.",
        "TitleAboutUs":"Conoce a nuestro",
        "team":"Equipo",
        "colimasoft":"Nosotros somos ColimaSoft",
        "values":"Nuestros valores",
        "vision": "Visión",
        "teamWork":"Equipo",
        "Passion":"Pasión",
        "GoodWill":"Esfuerzo",      
        "SM":"SM",
        "CTO":"CTO",
        "Developer":"Desarrollador",
        "Designer":"UX/UI",
        "pet":"Mascota",
        "OM":"Personal Administrativo",
        "Laura":"RH & FI",
        "DeveloperW":"Desarrolladora",
        /** */
        "TitleQuote":"SOLICITAR COTIZACIÓN",
        "Fullname":"Nombre completo",
        "instruction1":"Completa los siguientes campos:",
        "email":"Correo",
        "phone": "Número de teléfono",
        "instruction2":"Selecciona el tipo de diseño que deseas agregar a tu software.",
        "Custom":"Diseño personalizado",
        "template":"Plantilla",
        "none":"Ninguno",
        "instruction3":"Seleccionados.",
        "ecommerce":"E-commerce",
        "web":"Web",
        "app":"Aplicación móvil",
        "instruction4":"Seleciona las siguientes opciones que debe contener tu software.",
        "authentication":"Autenticación",
        "landingPage":"Landing page",
        "homePage":"Página de inicio ",
        "sections":"Secciones",
        "animation":"Animaciones",
        "comments":"Agregar especificaciones",
        "validationMsg1":"Demaciado corto", 
        "validationMsg2":"Requerido",
        "validationMsg3":"Correo inválido",
        "btnSend":"Enviar",
        "textBtnQuote":"Cotización",
        "catalogo":'Catálogo',
        "carrito":"Carrito de compras",
        'pago':"Método de pago",
        "integracionsap":"Integración con SAP",
        "CMS":"CMS",
        "facturacion":"Facturación eléctronica",
        "responsividad":'Diseño Responsivo',
        "navegacion":"Navegación",
        "loading":"Loading Page",
        "profile":"Perfil",
        "splashScreen":"Pantalla de Inicio",
        "chat":"Chat",
        "maps":"Maps",
        "iOS":"iOS",
        "Android":"Android",
        "pushNoti":"Notificaciones", 
        "InAppPurchases":"Compra desde App",
        "validationPhone":"Formato inválido",
        "validationPhone2":"Formato inválido",
        "btnCancelar": "Cancelar",
        "msgQuote":"Cotización enviada",
        "MsgSend":"Tu información ha sido enviada. Nos contactaremos lo más pronto posible.",
        "integration": "Integración",
        "blockChain": "BlockChain",
        "ML": "Machine Learning",
        "msgBox":"Escribe tus especificaciones...",
        "Arca":"Arca es una app de ecommerce, que se dedica a la venta de coronas y artículos religiosos, todos ellos de cristalería.",
        "textNotification1":"Bienvenido a ColimaSoft",
        "textNotification2":"Conoce todos nuestros proyectos.",
        "textNotification3":"Conoce todos nuestros servicios.",
        "SubtitleSlider":"Resolvemos tus retos de"
    }
}

export default Translate;