import React from "react";
import Logo from "../../Img/LOGO12.png";
import Instagram from "../../Img/Footer/instagram.png";
import Facebook from "../../Img/Footer/facebook.png";
import Twitter from "../../Img/Footer/twitter.png";
import Linkedln from "../../Img/Footer/link.png";
import Location from "../../Img/Footer/location.png";
import Services from "../../Img/Footer/services@colimasoft.com.png";
import Jobs from "../../Img/Footer/jobs@colimasoft.com.png";

import "./Footer.scss";

const Footer = (props) => {
  const { idioma } = props;

  return (
    <footer className="footer" id="Footer">
      <div className="container">
        <div className="row footer-row mb-2">
          <div className="col-xl-3 col-12 col-md-12 first-column">
            <div className="RSStyle">
              <a
                href="https://www.instagram.com/colimasoft/"
                aria-label="Link for Instagram"
              >
                <img
                  src={Instagram}
                  className=""
                  alt="Icon Instagram"
                  width="40"
                  height="40"
                ></img>
              </a>
              <a
                href="https://www.facebook.com/ColimaSoft/"
                aria-label="Link for Facrbook"
              >
                <img
                  src={Facebook}
                  className=""
                  alt="Icon Facebook"
                  width="40"
                  height="40"
                ></img>
              </a>
              <a
                href="https://www.linkedin.com/company/colimasoft-official/"
                aria-label="Link for Linkendln"
              >
                <img
                  src={Linkedln}
                  className=""
                  alt="Icon Linkendln"
                  width="40"
                  height="40"
                ></img>
              </a>
            </div>
            <h2>We code with love</h2>
          </div>


          <div className="col-xl-3 col-12 col-md-12 column service">
            <h3>{idioma.TitleColumn1}</h3>
            <button className="btn-services">
              <img
                src={Services}
                width="183"
                height="14"
                alt="email-services"
              ></img>
            </button>

          </div>
          <div className="col-xl-3 col-12 col-md-12 column service">
            <h3>{idioma.TitleColumn2}</h3>
            <button href="/" className="btn-services">
              <img src={Jobs} width="157" height="14" alt="email-job"></img>
            </button>
          </div>
          <div className="col-xl-3 col-md-12 col-12 column service">
            <h3>{idioma.Location}</h3>
            <div>
              <h4 className="fw-bold">
                <img
                  src={Location}
                  alt="Icon of location"
                  width="20"
                  height="20"
                ></img>{idioma.FirstL}
              </h4>

              <h4 className="fw-bold">
                <img
                  src={Location}
                  alt="Icon of location"
                  width="20"
                  height="20"
                ></img>{idioma.SecondL}
              </h4>
            </div>
          </div>
        </div>


        <div className="col-xl-2 col-12 col-md-12 mt social">
            <a
              href="https://www.instagram.com/colimasoft/"
              aria-label="Link for Instagram"
            >
              <img
                src={Instagram}
                className="mr-lg-3 ml-lg-5 mb-sm-5 sns-footer"
                alt="Icon Instagram"
                width="40"
                height="40"
              ></img>
            </a>
            <a
              href="https://www.facebook.com/ColimaSoft/"
              aria-label="Link for Facebook"
            >
              <img
                src={Facebook}
                className="mr-lg-3 mb-sm-5 sns-footer"
                alt="Icon Facebook"
                width="40"
                height="40"
              ></img>
            </a>
            <a
              href="https://www.linkedin.com/company/colimasoft-official/"
              aria-label="Link for Linkendl"
            >
              <img
                src={Linkedln}
                className="mr-lg-3 mb-sm-5 sns-footer"
                width="40"
                height="40"
                alt="Icon Linkendln"
              ></img>
            </a>
          </div>


      </div>
    </footer>
  );
};

export default Footer;
