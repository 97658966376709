import React from "react";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import logo from "../../Img/LOGO12.png";
 

const Whatsappbot = ({currentLanguage}) => {
    if( currentLanguage === 'ESP' ){    
        return (<>
            <WhatsAppWidget phoneNo='523121525667' headerIcon={logo} iconSize='60'
            headerTitle="ColimaSoft" chatPersonName="ColimaSoft" chatMessage="Hola, ¿en qué podemos ayudarle?" headerCaption="en línea" iconBgColor="#4FCE5D" iconColor="white"  />
        </>) 
    }else{
        return (<>
            <WhatsAppWidget phoneNo='523121525667' headerIcon={logo} iconSize='60' 
            headerTitle="ColimaSoft" chatPersonName="ColimaSoft" chatMessage="Hi, how can we help you?" iconBgColor="#4FCE5D" iconColor="white"/>
        </>)
    }
}
	

export default Whatsappbot;
