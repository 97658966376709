import React from 'react';
import Vision from '../../Img/Values/eye.png';
import TeamWorks from '../../Img/Values/support.png';
import Passion from '../../Img/Values/passionate.png';
import GoodWill from '../../Img/Values/peace.png';
import './Values.scss';

const Values = (props) => {
	const {idioma} = props;

	return (
		<div className="more-about-us-container">
			<div className="more-about-us-values">
				<h1 className="more-about-us-title">{idioma.values}</h1>

				<div className="values-box">
					<div className="values">
						<img src={Vision} alt="Vision" />
						<h1>{idioma.vision}</h1>
					</div>
				</div>
				<div className="values-box values-box2">
					<div className="values">
						<img src={TeamWorks} alt="Team Work" />
						<h1>{idioma.teamWork}</h1>
					</div>
				</div>
				<div className="values-box">
					<div className="values">
						<img src={Passion} alt="Passion" />
						<h1>{idioma.Passion}</h1>
					</div>
				</div>
				<div className="values-box">
					<div className="values">
						<img src={GoodWill} alt="Good Will" />
						<h1>{idioma.GoodWill}</h1>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Values;

