import React from 'react'
import GroupPoints from '../../Img/Team/Group-points.png'
import TeamPhoto from '../../Img/Team/Team.jpg'
import './Team.scss'

const Team = (props) => {
    const {idioma} = props
    return (
        <div className="container">
            <div className="content-team">
                <h1> {idioma.colimasoft} </h1>
                <div className='group-photo'>
                 <img src={TeamPhoto} className='photo' alt='Team'></img>   
                </div>
                
                <img src={GroupPoints} className='group' alt='points'></img>
                <img src={GroupPoints} className='group2' alt='points'></img>
            </div>
        </div>
    )
}

export default Team
