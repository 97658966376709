import React, { useState } from "react";
import ColimasoftLogo from "../../Img/192.png";
import "./NavBar.scss";
import { Modal } from "../Modal/Modal";

import MX from '../../Img/Slider/flag/MX.png'
import US from '../../Img/Slider/flag/US.png'


const NavBar = (props) => {
  const { idioma } = props;
  const [modalVisible, setModalVisible] = useState(false)
  const [idiomaPage, setIdiomaPage] = useState("ENG")
  const [flag, setFlag] = useState(US)
  const [navOption, setNavOption] = useState('home')
  const [openModal, setOpenModal] = useState(false);


  const handleChangeLanguage = () => {
    let currentIdioma = props.currentLanguage;

    if (currentIdioma === "ESP") {
      props.handleChangeLanguage('ENG')
      setIdiomaPage("ESP")
      setFlag(MX)
    }
    else {
      props.handleChangeLanguage('ESP')
      setIdiomaPage("ENG")
      setFlag(US)
    }
  }

  return (
    <div className="navBar-container">
      <div className="btnTranslate">
        <button onClick={() => handleChangeLanguage()} style={{ height: '29px', background: '#A294FC', borderRadius: '6px', border: 'none', font: '19px', fontWeight: 500, color: 'white', outline: 'none', display: 'flex', alignItems: 'center' }}>
          <img style={{ width: '30px', marginRight: '5px' }} width={30} height={16} crossOrigin='anonymous' src={flag} alt="flag" />
          {idiomaPage}
        </button>
      </div>
      <div className="logoNav">
        <a href="/">
        <img className="logo-nav" src={ColimasoftLogo} alt="ColimaSoft" width='140' height='90' />
        </a>
      </div>{
        window.location.pathname === "/portfolio" ?
          <div className="tabsPortfolio">
            <span><a href="/">{idioma.Home}</a></span>
            <span><a href="/portfolio">{idioma.Portfolio}</a></span>
            <span><a href="/about-us">{idioma.AboutUs}</a></span>
          </div>
          :
          <div className="tabs">
            <span><a href="/">{idioma.Home}</a></span>
            <span><a href="/portfolio">{idioma.Portfolio}</a></span>
            <span><a href="/about-us">{idioma.AboutUs}</a></span>
          </div>
      }
      <div className="Btns">
        {/* <button onClick={() => { setOpenModal(true) }}>{idioma.textBtnQuote}</button> */}
        <a href='https://calendly.com/zaudy-valencia'>{idioma.textBtnQuote}</a>
        <a href='#Footer'>{idioma.ContactUs}</a>
        {/* <div className="btnContact">
          <div>
            <a
              className="buttonContact"
              href='#Footer'
              aria-label="Contact Us for more information"
            >
              <span className="loader" id="loader">{idioma.ContactUs}</span>
            </a>
          </div>
        </div> */}
      </div>
      {openModal && <Modal closeModal={setOpenModal} idioma={idioma} />}

    </div>
  )
}


// class NavbarPage extends Component {

//   handleChangeLanguage() {
//     // eslint-disable-next-line no-unused-expressions
//     let currentIdioma = this.props.currentLanguage;

//     if (currentIdioma === 'ESP'){
//       this.props.handleChangeLanguage('ENG')
//       this.setState({idiomaPage:'ESP',flag:"https://countryflagsapi.com/png/mx"})
//     }
//     else{
//       this.props.handleChangeLanguage('ESP')
//       this.setState({idiomaPage:'ENG',flag:"https://countryflagsapi.com/png/us"})
//     }
//   }

//   state = {
//     collapseID: "",
//     modalVisible: false,
//     navOption: "Home",
//     idiomaPage: "ENG",
//     flag:"https://countryflagsapi.com/png/us"
//   };


//   onItemClick = (option) => {
//     this.setState({
//       navOption: option,
//     });
//   };

//   // toggleCollapse = (collapseID) => () =>
//   //   this.setState((prevState) => ({
//   //     collapseID: prevState.collapseID !== collapseID ? collapseID : "",
//   //   }));

//   // render() {
//   //   const {idioma} = this.props;
//   //   return (
//   //     <MDBNavbar
//   //       expand="lg"
//   //       light
//   //       className="navbar nav-1440 back justify-content-xl-between justify-content-sm-around"
//   //     >
//   //       <MDBNavbarBrand title="ColimaSoft">
//   //         <div className="nav-img-title">
//   //           <a href="/">
//   //             {" "}
//   //             <img className="logo-nav" src={ColimasoftLogo} alt="ColimaSoft" width='173' height='85' />
//   //           </a>
//   //         </div>
//   //       </MDBNavbarBrand>

//   //          <div className="btnTranslate">
//   //           <button onClick={() => this.handleChangeLanguage()} style={{ height: '29px', background:'#A294FC', borderRadius:'6px', border:'none', font: '19px', fontWeight: 500,color: 'white', outline:'none',display:'flex', alignItems:'center' }}>
//   //             <img style={{width: '30px',marginRight:'5px'}} width={30} height={16} crossOrigin="anonymous" src={this.state.flag } alt={'flag'}></img>
//   //             {this.state.idiomaPage}</button>
//   //         </div>
//   //         <MDBNavbarNav left >
//   //           <MDBNavItem>
//   //             <MDBNavLink
//   //               active
//   //               aria-current="page"
//   //               to="/"
//   //               className="textHeader"
//   //               onClick={() => {
//   //                 this.onItemClick("nav-Home");
//   //               }}
//   //             >
//   //               {idioma.Home}
//   //               <div className="dot"></div>
//   //             </MDBNavLink>
//   //           </MDBNavItem>
//   //           <MDBNavItem>
//   //             <MDBNavLink
//   //               active
//   //               aria-current="page"
//   //               to="/portfolio"
//   //               className="textHeader"
//   //               onClick={() => {
//   //                 this.onItemClick("portfolio");
//   //               }}
//   //             >
//   //               {idioma.Portfolio}
//   //               <div className="dot ml-3"></div>
//   //             </MDBNavLink>
//   //           </MDBNavItem>
//   //           <MDBNavItem>
//   //             <MDBNavLink
//   //               active
//   //               style={{with:'100px !important'}}
//   //               aria-current="page"
//   //               to="/about-us"
//   //               className="about textHeader"
//   //               onClick={() => {
//   //                 this.onItemClick("portfolio");
//   //               }}
//   //             >
//   //               {idioma.AboutUs}
//   //               <div className="dot ml-3"></div>
//   //             </MDBNavLink>
//   //           </MDBNavItem>
//   //           <MDBNavItem className="btnContact">
//   //             <MDBAnimation type="pulse" infinite>
//   //               <a
//   //                 className="buttonContact"
//   //                 href='#Footer'
//   //                 aria-label="Contact Us for more information"
//   //               >
//   //                 <span className="loader" id="loader">{idioma.ContactUs}</span>
//   //               </a>
//   //             </MDBAnimation>
//   //           </MDBNavItem>
//   //         </MDBNavbarNav>
//   //       {/* </MDBCollapse> */}
//   //     </MDBNavbar>
//   //   );
//   // }
// }

export default NavBar;