import React from 'react'
import ChatBot from '../ChatBot'
import WhatsappBot from '../WhatsappBot'
import './ChatBubble.scss'

const ChatBotBubble = (props) => {
    const { idioma } = props;

  return (
    <div className='containerBtn' >
        <ChatBot />
        <div className='whatsapp_widget_wrapper'>
        <WhatsappBot  currentLanguage={idioma}  />
        </div>
    </div>
  )
}

export default ChatBotBubble