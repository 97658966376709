import React from 'react';
import MapUSA from '../../Img/Maps/USA.png';
import MapMex from '../../Img/Maps/Mexico.png';
import './MapContent.scss';

const MapContent = (props) => {
	const {idioma} = props;
	return (
		<div className="container">
			<div className="title-container">
				<div>
					<h1>{idioma.Location}</h1>
				</div>
			</div>
			<div className="row">
				<div className="col-sm countrys">
					<div className="col-lg-6  team-work-map">
						<img src={MapUSA} alt="USA" className='map-usa'/>
					</div>
					<div className="col-lg-6  team-work-map">
						<img src={MapMex} alt="Mexico" className='map'/>
					</div>
				</div>
			</div>

		</div>
	);
};

export default MapContent;
