import React from "react";
import OurProjects from "../../Components/OurProjects";
import "./Portfolio.scss";
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer";
import Copyright from "../../Components/Copyright";

const Portfolio = (props) => {
  // document.title = "Colima Soft - Portfolio";
  // const metaDescription = document.querySelector('meta[name="description"]');
  const description = "Us Portfolio";
  const { idioma } = props;

  // metaDescription.setAttribute('content', description);

  return (
    <div className="portfolio-container">
      <Helmet>
        <title>ColimaSoft - Portfolio</title>
        <meta name="description" content={description}></meta>
        <link id="canonical" rel="canonical" href={window.location.href} />
      </Helmet>

      <section className="section">
        <OurProjects idioma={idioma} />
      </section>
      <section>
        <Footer idioma={idioma} />
      </section>
      <section>
        <Copyright idioma={idioma} />
      </section>
    </div>
  );
};

export default Portfolio;
