import React from 'react'
import './Modal.scss'

import Check from '../../Img/check-circle.png'

const ModalAlert = ({closeModal, idioma}) => {
  return (
    <div className='modalAlertaBack'>
        <div className='alert'>
            <div className='linea'></div>
            <img src={Check} alt="" />
            <p className="textMdlAlert">
            {idioma.MsgSend}
            </p>
            <a className='closeModal' href='/'> OK</a>
        </div>
    </div>
  )
}

export default ModalAlert