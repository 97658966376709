import React from 'react';
import Colimotl from '../../../Img/Portfolio/MobileApp/Colimotl.png';
import Lively from '../../../Img/Portfolio/MobileApp/Lively.png';
import TaxiCDMX from '../../../Img/Portfolio/MobileApp/TaxiCDMX.png';
import TuVotoSi from '../../../Img/Portfolio/MobileApp/TuVotoSi.png';
import REACT from '../../../Img/DevelopmentTechnologies/REACT.png';
import PHP from '../../../Img/DevelopmentTechnologies/PHP.png';
import JAVA from '../../../Img/DevelopmentTechnologies/JAVA.png';
import ANGULAR from '../../../Img/DevelopmentTechnologies/ANGULAR.png';
import SWIFT from '../../../Img/DevelopmentTechnologies/SWIFT.png';
import KOTLIN from '../../../Img/DevelopmentTechnologies/KOTLIN.png';
import IONIC from '../../../Img/DevelopmentTechnologies/IONIC.png';
import FLUTTER from '../../../Img/DevelopmentTechnologies/FLUTTER.png';
import DENTAL from '../../../Img/Portfolio/MobileApp/dental-chavez.png';
import IDMISSION from '../../../Img/Portfolio/MobileApp//idmission.png';
import AEROTRON from '../../../Img/Portfolio/MobileApp/Aerotron.png'
import WEBSERVICES from '../../../Img/Portfolio/MobileApp/webservice.png'
import KIOSKO from '../../../Img/Portfolio/MobileApp/Kiosko.png'
import TailGate from '../../../Img/Portfolio/MobileApp/TailGate.png'
import Arca from '../../../Img/Portfolio/MobileApp/Arca.png'
import FIREBASE from '../../../Img/Portfolio/MobileApp/firebase.png'
import DART from '../../../Img/Portfolio/MobileApp/dart.png'
import NODEJS from '../../../Img/DevelopmentTechnologies/NODEJS.png';

import Slider from "react-slick";

import './CarouselMobile.scss';

const CarouselWeb = (props) => {
	const { idioma } = props;
	var settings = {
		dots: true,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: false,
		autoplaySpeed: 5000,
		pauseOnHover: true,
		responsive:
			[
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}

				},
				{
					breakpoint: 320,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 1140,
					settings: {
						slidesToShow: 2,
					}
				},
			]

	};

	return (
		<div className="" >
			<Slider {...settings}>
				<div className="card-carouselWeb" >
					<div className='wrapperImage'>
						<img
							className="imgWeb"
							src={KIOSKO}
							alt="First slide"
						/>
					</div>
					<h1 className="title-card">Kiosko</h1>
					<h4 className="description-card">
						{idioma.Kiosko}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={KOTLIN}
								alt="KOTLIN"
								className="iconTechnology"
							/>
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<div className='wrapperImage'>
						<img
							className="imgWeb"
							src={Colimotl}
							alt="First slide"
						/>
					</div>
					<h1 className="title-card">Colimotl food</h1>
					<h4 className="description-card">
						{idioma.Colimotl}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={REACT}
								alt="RN"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={PHP}
								alt="PHP"
								className="iconTechnology"
							/>
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<div className='wrapperImage'>
						<img
							className="imgWeb"
							src={Lively}
							alt="First slide"
						/>
					</div>
					<h1 className="title-card">Lively</h1>
					<h4 className="description-card">
						{idioma.Lively}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={SWIFT}
								alt="SWIFT"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={KOTLIN}
								alt="KOTLIN"
								className="iconTechnology"
							/>
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<div className='wrapperImage'>
						<div className='wrapperImage'>
							<img
								className="imgWeb"
								src={TaxiCDMX}
								alt="First slide"
							/>
						</div>
					</div>
					<h1 className="title-card">Taxi CDMX</h1>
					<h4 className="description-card">
						{idioma.TaxiCDMX}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={IONIC}
								alt="IONIC"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={ANGULAR}
								alt="ANGULAR"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={PHP} alt="PHP" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<div className='wrapperImage'>
						<img
							className="imgWeb"
							src={TuVotoSi}
							alt="TuVotoSi"
						/>
					</div>
					<h1 className="title-card">Tu Voto Si MX</h1>
					<h4 className="description-card">
						{idioma.TuVotoSiMX}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={IONIC}
								alt="IONIC"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={JAVA}
								alt="JAVA"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={ANGULAR} alt="ANGULAR" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<div className='wrapperImage'>
						<img
							className="imgWeb"
							src={DENTAL}
							alt="DENTAL"
						/>
					</div>
					<h1 className="title-card">Dra. Ivett Chávez</h1>
					<h4 className="description-card">
						{idioma.DraIvettChávez}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={IONIC}
								alt="IONIC"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={ANGULAR}
								alt="ANGULAR"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={PHP} alt="PHP" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<div className='wrapperImage'>
						<img
							className="imgWeb"
							src={IDMISSION}
							alt="IDMISSION"
						/>
					</div>
					<h1 className="title-card">IDMission</h1>
					<h4 className="description-card">
						{idioma.IDmission}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={IONIC}
								alt="IONIC"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={PHP}
								alt="PHP"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={JAVA} alt="JAVA" className="iconTechnology" />
						</div>
						<div className="iconTechnology">
							<img src={SWIFT} alt="SWIFT" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<div className='wrapperImage'>
						<img
							className="imgWeb"
							src={TailGate}
							alt="TailGate"
						/>
					</div>
					<h1 className="title-card">Team TailGate</h1>
					<h4 className="description-card">
						{idioma.TailGate}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={FLUTTER}
								alt="FLUTTER"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={FIREBASE}
								alt="FIREBASE"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={DART}
								alt="DART"
								className="iconTechnology"
							/>
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<div className='wrapperImage'>
						<img
							className="imgWeb"
							src={Arca}
							alt="Arca"
						/>
					</div>
					<h1 className="title-card">Arca</h1>
					<h4 className="description-card">
						{idioma.Arca}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={REACT}
								alt="REACT"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={NODEJS}
								alt="NODEJS"
								className="iconTechnology"
							/>
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<div className='wrapperImage'>
						<img
							className="imgWeb"
							src={AEROTRON}
							alt="AEROTRON"
						/>
					</div>
					<h1 className="title-card">Aerotron</h1>
					<h4 className="description-card">
						{idioma.Aerotron}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={FLUTTER}
								alt="FLUTTER"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={WEBSERVICES}
								alt="WEBSERVICES"
								className="iconTechnology"
							/>
						</div>
					</div>
				</div>
			</Slider>
		</div>
	);
};

export default CarouselWeb;
