import React from "react";

const Copyright = () => {
  return (
    <div>
      <p className="black-text text-center font-weight-normal">
        &copy; {new Date().getFullYear()} {" ColimaSoft. All rights reserved. "}{" "}
      </p>
    </div>
  );
};

export default Copyright;
