import React from "react";
import MessengerCustomerChat from 'react-messenger-customer-chat';
 

const Chatbot = () => {

return	<MessengerCustomerChat  pageId="124699554381734" appId="808924243299048" style={{booton:'100px'}} />	

}
	

export default Chatbot;
