import React from 'react'
import './BtnUp.scss'
import Flecha from '../../Img/arrow.png'

const BtnUp = () => {
    const scrollTop = () => {
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    }

  return (
    <div className='btnUp'>
        <button className='btnStyle' onClick={scrollTop}>
            <img src={Flecha} alt='up' height={25} width={25} />
        </button>
    </div>
  )
}

export default BtnUp