import React from 'react';
import ReactDOM from 'react-dom';
import './Styles/index.css';
import { BrowserRouter  as Router} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

const app = (
  <Router>
      <App />
  </Router>
)
ReactDOM.render(app, document.getElementById('root'));
// ReactDOMServer.renderToString()
reportWebVitals();
