
import React from 'react';
import Request_A_Quote from '../../Components/RequestAQuote';
import './Request.scss';
import {Helmet} from 'react-helmet';

const RequestAQoute = () => {
	// document.title = "Request A Quote";
	// const metaDescription = document.querySelector('meta[name="description"]');
	const description = "Make a Request A Quote";
	// metaDescription.setAttribute('content', description);
	return (
		<div className="request-a-coute-container">
			<Helmet>
				<title>Colima Soft - About Us</title>
				<meta name="description" content={description}></meta>
				<link id="canonical" rel="canonical" href={window.location.href} />
			</Helmet>
			<section className="section">
				<Request_A_Quote />
			</section>
		</div>
	);
};

export default RequestAQoute;
