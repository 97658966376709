import React from 'react';
import Values from '../../Components/Values';
import MapContent from '../../Components/MapContent';
import './AboutUs.scss';
import {Helmet} from 'react-helmet';
import Footer from '../../Components/Footer';
import Copyright from '../../Components/Copyright';
import Team from '../../Components/Team';
 

const AboutUs = (props) => {
	const {idioma} = props;
	// document.title = "Colima Soft - About Us";
	// const metaDescription = document.querySelector('meta[name="description"]');
	const description = "Meet Us";
	// metaDescription.setAttribute('content', description);
	return (
		<div className="aboutUs-container">
			<Helmet>
				<title>ColimaSoft - About Us</title>
				<meta name="description" content={description}></meta>
				<link id="canonical" rel="canonical" href={window.location.href} />
			</Helmet>

			<section style={{ marginTop: '80px' }}>
				<Team idioma={idioma}/>
			</section>
			<section>
				<Values idioma={idioma}/>
			</section>
			<section>
				<MapContent idioma={idioma}/>
			</section>
			<section>
				<Footer idioma={idioma}/>
			</section>
			<section>
				<Copyright idioma={idioma}/>
			</section>
		</div>
	);
};

export default AboutUs;
