import React from 'react';
import BayWare from '../../../Img/Portfolio/Ecommerce/BayWare.png';
import Brantano from '../../../Img/Portfolio/Ecommerce/Brantano.png';
import Calzado from '../../../Img/Portfolio/Ecommerce/Calzado.png';
import HorseCreek from '../../../Img/Portfolio/Ecommerce/HorseCreek.png';
import MaderasPolanco from '../../../Img/Portfolio/Ecommerce/MaderasPolanco.png';
import Surtidora from '../../../Img/Portfolio/Ecommerce/Surtidora.png';
import TexasTrue from '../../../Img/Portfolio/Ecommerce/TexasTrue.png';
import REACT from '../../../Img/DevelopmentTechnologies/REACT.png';
import SHOPIFY from '../../../Img/DevelopmentTechnologies/SHOPIFY.png';
import PHP from '../../../Img/DevelopmentTechnologies/PHP.png';
import CSS from '../../../Img/DevelopmentTechnologies/CSS.png';
import LIQUID from '../../../Img/DevelopmentTechnologies/LIQUID.png';
import JAVASCRIPT from '../../../Img/DevelopmentTechnologies/JAVASCRIPT.png';
import VTEX from '../../../Img/DevelopmentTechnologies/VTEX.png';
import NODEJS from '../../../Img/DevelopmentTechnologies/NODEJS.png';
import BOOTSTRAP from '../../../Img/DevelopmentTechnologies/BOOTSTRAP.png';
import './CarouselEcommerce.scss';
import Slider from "react-slick";


const CarouselWeb = (props) => {
	const { idioma } = props;
	var settings = {
		dots: true,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: true,
		responsive:
			[
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}

				},
				{
					breakpoint: 320,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 1140,
					settings: {
						slidesToShow: 2,
					}
				},
			]

	};

	return (
		<div className="" >
			<Slider {...settings}>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={MaderasPolanco}
						alt="First slide"
					/>
					<h1 className="title-card">Maderas Polanco</h1>
					<h4 className="description-card">
						{idioma.MaderasPolanco}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={REACT}
								alt="REACT"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={NODEJS}
								alt="NODEJS"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={BOOTSTRAP} alt="BOOTSTRAP" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={BayWare}
						alt="First slide"
					/>
					<h1 className="title-card">BayWare</h1>
					<h4 className="description-card">
						{idioma.BayWare}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={PHP}
								alt="PHP"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={JAVASCRIPT}
								alt="JAVASCRIPT"
								className="iconTechnology"
							/>
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={Calzado}
						alt="First slide"
					/>
					<h1 className="title-card">Calzzapato</h1>
					<h4 className="description-card">
						{idioma.Calzzapato}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={REACT}
								alt="REACT"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={NODEJS}
								alt="NODEJS"
								className="iconTechnology"
							/>
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={HorseCreek}
						alt="First slide"
					/>
					<h1 className="title-card">Horse Creek</h1>
					<h4 className="description-card">
						{idioma.HorseCreek}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={SHOPIFY}
								alt="SHOPIFY"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={LIQUID}
								alt="LIQUID"
								className="iconTechnology"
							/>
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={Brantano}
						alt="First slide"
					/>
					<h1 className="title-card">Brantano</h1>
					<h4 className="description-card">
						{idioma.Brantano}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={SHOPIFY}
								alt="SHOPIFY"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={LIQUID}
								alt="LIQUID"
								className="iconTechnology"
							/>
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={Surtidora}
						alt="First slide"
					/>
					<h1 className="title-card">Surtidora departamental</h1>
					<h4 className="description-card">
						{idioma.Surtidoradepartamental}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={VTEX}
								alt="VTEX"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={REACT}
								alt="REACT"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={NODEJS} alt="NODEJS" className="iconTechnology" />
						</div>
					</div>
				</div>
				<div className="card-carouselWeb" >
					<img
						className="d-block w-100 imgWeb"
						src={TexasTrue}
						alt="First slide"
					/>
					<h1 className="title-card">Texas True Threads</h1>
					<h4 className="description-card">
						{idioma.TexasTrueThreads}
					</h4>
					<div className='wrapper-icons'>
						<div className="iconTechnology">
							<img
								src={SHOPIFY}
								alt="SHOPIFY"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img
								src={LIQUID}
								alt="Liquid"
								className="iconTechnology"
							/>
						</div>
						<div className="iconTechnology">
							<img src={CSS} alt="CSS" className="iconTechnology" />
						</div>
					</div>
				</div>
			</Slider>
		</div>
	);
};

export default CarouselWeb;
