import { Field, Form, Formik } from 'formik';
import React, { useState, useRef } from 'react';
import './Modal.scss';
import emailjs from '@emailjs/browser'
import SphereH3 from "../../Img/Slider/3.png";

import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import ModalAlert from './ModalAlert';

import X from '../../Img/x.png'

export const Modal = ({ closeModal, idioma }) => {
	const captcha = useRef(null)
	const [openModal, setOpenModal] = useState(false);
	const [verifiedCaptcha, setVerifiedCaptcha] = useState(false)

	const options = [
		{ value: "Customizado", label: idioma.Custom, id: "custom" },
		{ value: "ecommerce", label: idioma.ecommerce, id: "ecommerce" },
		{ value: "Plantilla", label: idioma.template, id: "template" },
		{ value: "Web", label: idioma.web, id: 'web' },
		{ value: "App", label: idioma.app, id: 'app' },
		{ value: "Autenticacion", label: idioma.authentication, id: 'authentication' },
		{ value: "LandingPage", label: idioma.landingPage, id: 'landingPage' },
		{ value: "homePage", label: idioma.homePage, id: 'homePage' },
		{ value: "Sections", label: idioma.sections, id: 'sections' },
		{ value: "Animaciones", label: idioma.animation, id: 'animation' },
		{ value: "Catalogo", label: idioma.catalogo, id: 'catalogo' },
		{ value: "Carrito", label: idioma.carrito, id: 'carrito' },
		{ value: "MetodoPago", label: idioma.pago, id: 'pago' },
		{ value: "integracionSAP", label: idioma.integracionsap, id: 'integracionsap' },
		{ value: "CompraEnApp", label: idioma.InAppPurchases, id: 'InAppPurchases' },
		{ value: "Responsividad", label: idioma.responsividad, id: 'responsividad' },
		{ value: "Navegacion", label: idioma.navegacion, id: 'navegacion' },
		{ value: "CMS", label: idioma.CMS, id: 'CMS' },
		{ value: "Loading", label: idioma.loading, id: 'loading' },
		{ value: "Profile", label: idioma.profile, id: 'profile' },
		{ value: "splashScreen", label: idioma.splashScreen, id: 'splashScreen' },
		{ value: "Chat", label: idioma.chat, id: 'chat' },
		{ value: "Maps", label: idioma.maps, id: 'maps' },
		{ value: "Integration", label: idioma.integration, id: 'Integration' },
		{ value: "Machine_Learning", label: idioma.blockChain, id: 'Machine_Learning' },
		{ value: "Block_Chain", label: idioma.ML, id: 'Block_Chain' },
		{ value: "Notificaciones", label: idioma.pushNoti, id: 'pushNoti' },
		{value:'Other', label: 'Other', id:'other'}
	];

	const FormValidation = Yup.object().shape({
		first_name: Yup.string()
			.min(2, idioma.validationMsg1)
			.required(idioma.validationMsg2),
		email: Yup.string().email(idioma.validationMsg3).required(idioma.validationMsg2),
		
	});

	const sendEmail = (e) => {
		e.preventDefault();

		console.log(e.target.value)
		
 		emailjs.sendForm('service_hz739to', 'template_kqwqkni', e.target, 'BIQEmxzpC11_JQc7F')
			.then((result) => {
				console.log(result.text);
			}, (error) => {
				console.log(error.text);
			});
	};

	const onChange = () => {
		if(captcha.current.getValue()){
			setVerifiedCaptcha(true)
		}
	}




	const listItems = options.map((option, index) => {
		return (

			<div className="checkbox">
				<Field type="checkbox" name={option.value} id={option.id} value={option.value} />
				<label htmlFor={option.id} id={option.id} >{option.label}</label>
			</div>
		)
	})

	return (
		<div className='modalBack'>
			<div className='ContainerModal'>
				<img src={SphereH3} alt="Sphere" className='sphereh'></img>
				<div className='boxModal'></div>
				<div className='headerModal'>
				<button className='btnClose' onClick={() => { closeModal(false) }}><img src={X} alt='close'></img></button>
				<h1 className='titleModal'>{idioma.instruction1}</h1>
				</div>
				{openModal && <ModalAlert closeModal={setOpenModal} idioma={idioma} />}
				<hr />
				<Formik
					initialValues={{
						first_name: '',
						email: '',
						phone: '',
						Customizado: '',
						Template: '',
						web: "",
						app: "",
						authentication: '',
						landingPage: '',
						homePage: '',
						sections: '',
						catalogo: '',
						carrito: '',
						pago: '',
						integracionsap: '',
						CMS: '',
						responsividad: '',
						navegacion: '',
						loading: '',
						profile: '',
						splashScreen: '',
						chat: '',
						maps: '',
						pushNoti: '',
						InAppPurchases: '',
						comments: '',
						ecommerce: '', 
						other:''
					}}
					validationSchema={FormValidation}
					onSubmit={values => {
						sendEmail(values)
					}}>
					{({ values, handleSubmit, errors, touched, isSubmitting, handleBlur }) => (
						<Form onSubmit={handleSubmit && sendEmail  }>
							<div className='formContact'>
								<div>
								<label htmlFor="" className='textForm'>{idioma.Fullname} *:</label>
								<Field
									name="first_name"
									type='text'
									value={values.first_name}
									className='Field'
									onBlur={handleBlur}
								/>
								{errors.first_name && touched.first_name ? (
									<div className='error'>{errors.first_name}</div>
								) : null}
								</div>
								<div>
								<label htmlFor="" className='textForm'>{idioma.email} *:</label>
								<Field
									type='email'
									name='email'
									onBlur={handleBlur}
									value={values.email}
									className='Field'
								/>
								{errors.email && touched.email ? <div className='error'>{errors.email}</div> : null}
								</div>
								<div>
								<label htmlFor="" className='textForm'>{idioma.phone}:</label>
								<Field
									type="tel"
									value={values.phone}
									name='phone'
									className='Field'
									onBlur={handleBlur}
									maxLength={10}
								/>
								{errors.phone && touched.phone ? <div className='error'>{errors.phone}</div> : null}
								</div>
							</div>
							<hr />
							<div className="checkbox">
								<h6 className='instruction2'>{idioma.instruction2}</h6>
								{
									listItems
								}
							</div>
							<hr />
								<div>
									<textarea placeholder={idioma.msgBox} cols="50" rows="6" wrap="hard" className='commentsArea' name='comments'></textarea>
								</div>
							<div className='recaptcha'>
							<ReCAPTCHA
								sitekey="6LdXICshAAAAAAcOtqfmPl4WtMecvOjCcwEiV1FG"
								onChange={onChange}
								ref={captcha}
							/>
							</div>
							<div className='btnBtm'>
							<button className='btnSend' type="submit" disabled={values.email.length < 1 || values.first_name.length < 1 || verifiedCaptcha === false} onClick={() => setOpenModal(true)}>{idioma.btnSend}</button>
							<a className='btnCancel' onClick={() => { closeModal(false) }}>{idioma.btnCancelar}</a>
							</div>

						</Form>
					)}
				</Formik>
			</div>
		</div>
	)
}
