import React, { useState } from "react";
import ColimasoftLogo from "../../Img/LOGO12.png";
import "./Nav.scss";
import Menu from '../../Img/Slider/material-symbols_menu.png'
import X from '../../Img/x.png'
import MX from '../../Img/Slider/flag/MX.png'
import US from '../../Img/Slider/flag/US.png'
import { Modal } from "../Modal/Modal";


const Nav = (props) => {
  const { idioma } = props;
  const [modalVisible, setModalVisible] = useState(false)
  const [idiomaPage, setIdiomaPage] = useState("ENG")
  const [flag, setFlag] = useState(US)

  const [openModal, setOpenModal] = useState(false);

  const handleChangeLanguage = () => {

    console.log(props.currentLanguage);

    console.log(idiomaPage);

    let currentIdioma = props.currentLanguage;

    if (currentIdioma === "ESP") {
      props.handleChangeLanguage('ENG')
      setIdiomaPage("ESP")
      setFlag(MX)
    }
    else {
      props.handleChangeLanguage('ESP')
      setIdiomaPage("ENG")
      setFlag(US)
    }
  }

  return (
    <div className="nav2">
      <div className="nav-img-title">
        <a href="/">
          {" "}
          <img className="logo-nav" src={ColimasoftLogo} alt="ColimaSoft" width='120' height='74' />
        </a>
      </div>
      <button className="bar-btn" onClick={() => setModalVisible(true)}>
        <img src={Menu} width='40' height={'40'} alt="menu" />
      </button>
      <div className="btnTranslate">
        <button onClick={() => handleChangeLanguage()} style={{ height: '29px', background: '#A294FC', borderRadius: '6px', border: 'none', font: '19px', fontWeight: 500, color: 'white', outline: 'none', display: 'flex', alignItems: 'center' }}>
          <img style={{ width: '30px', marginRight: '5px' }} width={30} height={16} crossOrigin='anonymous' src={flag} alt="flag" />
          {idiomaPage}
        </button>
      </div>
      {
        modalVisible === true ? (
          <div className="divMenu">
            <button className='btnCloseModal' onClick={() => { setModalVisible(false) }}><img src={X} alt='close'></img></button>
            <ul className="navMenu">
              <li><a href="/" rel="preconnect" >{idioma.Home}</a></li>
              <li><a href="/portfolio" rel="preconnect">{idioma.Portfolio}</a></li>
              <li><a href="/about-us" rel="preconnect">{idioma.AboutUs}</a></li>
              <a onClick={() => { setOpenModal(true) }} className="btnQuote" rel="preconnect" aria-label="Contact Us">{idioma.textBtnQuote}</a>
              <a href="#Footer" className="btnContactUs" rel="preconnect" aria-label="Contact Us">{idioma.ContactUs}</a>
            </ul>
          </div>
        )
          : null
      }
      {openModal && <Modal closeModal={setOpenModal} idioma={idioma} />}
    </div>
  )
}


export default Nav;
